import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';
import { Button, Form, FormGroup, Input, FormFeedback } from 'reactstrap';
import * as yup from 'yup';
import { Auth } from 'aws-amplify';
import { getValidationResult, fieldIsInvalid, fieldIsRequired } from '../utils/tools';
import Layout from '../components/layout/Layout';
import CardHeading from '../components/layout/CardHeading';
import CardFooter from '../components/layout/CardFooter';

import Error from '../components/Error';
import { localStorageSet } from '../utils/local-storage';
import { MessageModal } from '../components/modals/Modals';
import User from '../utils/auth';
import useForm from '../hooks/useForm';
import useRequest from '../hooks/useRequest';

const heading = {
  title: 'Create Account',
};

const registerSchema = yup.object({
  email: yup.string().required(fieldIsRequired).email(fieldIsInvalid),
  password: yup.string().min(8, 'Must have at least 8 characters').required(fieldIsRequired),
  confirmPassword: yup
    .string()
    .required(fieldIsRequired)
    .oneOf([yup.ref('password'), null], 'Passwords must match'),
});

const confirmSchema = yup.object({
  authCode: yup.string().label('Authorization Code').required(fieldIsRequired),
});

export const CreateAccountElements = ({
  defaultStage = 0,
  defaultInputData = {
    email: '',
    password: '',
    confirmPassword: '',
    authCode: '',
  },
}) => {
  const [stage, setStage] = useState(defaultStage);
  const [{ fields, errors }, { onElementChange, onValidate }] = useForm({
    initialFields: defaultInputData,
    validator,
    onValid,
  });
  const { email, password, confirmPassword, authCode } = fields;
  const [onSignup, signupState] = useRequest({
    request: () =>
      Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
        },
      }),
    onSuccess(response) {
      localStorageSet('_sub', response.userSub);
      setStage(1);
    },
  });
  const [onSignin, signInState] = useRequest({
    request: () => User.signin(email, password),
    onSuccess: toggleModal,
  });
  const [onConfirmSignup, confirmSignupState] = useRequest({
    request: () => Auth.confirmSignUp(email, authCode),
    onSuccess: onSignin,
  });
  const [showModal, setShowModal] = useState(false);

  const location = () => {
    navigate('/app/change-your-plan');
  };

  const msg = {
    class: 'primary',
    title: 'Create Account',
    message: (
      <div>
        Account successfully created. Please continue to choose your plan.
        <br />
      </div>
    ),
    devMsg: '',
    buttons: ['Okay'],
    action: [location],
  };

  const isLoading = signupState.loading || signInState.loading || confirmSignupState.loading;
  return {
    stage,
    error: signupState.error || signInState.error || confirmSignupState.error,
    showModal,
    msg,
    toggleModal,
    isLoading,

    // Stage 0
    emailField: {
      onChange: onElementChange,
      value: email,
    },
    passwordField: {
      onChange: onElementChange,
      value: password,
    },
    confirmPasswordField: {
      onChange: onElementChange,
      value: confirmPassword,
    },
    createButton: {
      onClick: onValidate,
      disabled: isLoading,
    },

    // Stage 1
    authCodeField: {
      onChange: onElementChange,
      value: authCode,
    },
    confirmButton: {
      onClick: onValidate,
      disabled: isLoading,
    },
    errors,
  };

  function toggleModal() {
    setShowModal(!showModal);
  }

  function validator(data) {
    return getValidationResult(data, stage === 0 ? registerSchema : confirmSchema);
  }

  function onValid(data) {
    return stage === 0 ? onSignup(data) : onConfirmSignup(data);
  }
};

const CreateAccount = (props) => {
  const {
    stage,
    error,
    isLoading,
    msg,
    showModal,
    toggleModal,
    emailField,
    passwordField,
    confirmPasswordField,
    createButton,
    authCodeField,
    confirmButton,
    errors,
  } = CreateAccountElements(props);

  return (
    <Layout data-test="create-account-component" hideIcons>
      <CardHeading heading={heading} />
      {stage === 0 && (
        <Form autoComplete="on">
          {error && <Error data-test="create-account-component-error" errorMessage={error} />}
          <FormGroup>
            <Input invalid={errors.email} name="email" id="email" placeholder="Email" {...emailField} />
            {errors.email && <FormFeedback>{errors.email}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              name="password"
              invalid={errors.password}
              id="password"
              placeholder="Password"
              autoComplete="on"
              {...passwordField}
            />
            {errors.password && <FormFeedback>{errors.password}</FormFeedback>}
          </FormGroup>
          <FormGroup>
            <Input
              type="password"
              name="confirmPassword"
              invalid={errors.confirmPassword}
              id="confirmPassword"
              placeholder="Confirm Password"
              autoComplete="on"
              {...confirmPasswordField}
            />
            {errors.confirmPassword && <FormFeedback>{errors.confirmPassword}</FormFeedback>}
          </FormGroup>
          <Button
            data-test="create-account-component-create-button"
            type="submit"
            color="primary"
            block
            {...createButton}
          >
            {!isLoading ? 'Create an account' : 'Please wait...'}
          </Button>
          <p className="text-center t-terms">
            By creating an account, you agree to our{' '}
            <Link to="/app/terms" className="link-nonTop link-heading">
              Terms
            </Link>
          </p>
        </Form>
      )}
      {stage === 1 && (
        <>
          {error && (error.code === 'cantAuthenticate' || error.code === 'CodeMismatchException') && (
            <Error data-test="create-account-component-error" errorMessage={error} />
          )}
          <FormGroup
            className={
              error && (error.code === 'cantAuthenticate' || error.code === 'CodeMismatchException') ? 'form-error' : ''
            }
          >
            <Input
              invalid={errors.authCode}
              type="text"
              name="authCode"
              id="authCode"
              placeholder="Authorization Code"
              {...authCodeField}
            />
            {errors.authCode && <FormFeedback>{errors.authCode}</FormFeedback>}
          </FormGroup>
          <Button data-test="create-account-component-confirm-button" color="primary" block {...confirmButton}>
            {!isLoading ? 'Confirm Sign up' : 'Please wait...'}
          </Button>
        </>
      )}
      <CardFooter>
        <p className="t-text">
          Already have an account?{' '}
          <Link to="/app/signin" className="link-heading">
            Sign In
          </Link>
        </p>
      </CardFooter>

      <MessageModal
        title={msg.title}
        color={msg.class}
        modal={showModal} // current status
        setModal={toggleModal} // action to change Boolean
        buttons={msg.buttons}
        actions={msg.action}
      >
        <div className="text-center">
          <>{msg.message}</>
          <div style={{ width: '20px', height: '20px' }} />
        </div>
      </MessageModal>
    </Layout>
  );
};

export default CreateAccount;
